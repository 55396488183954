import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import DatePicker from 'react-datepicker';
import moment from 'moment';

class App extends Component {
	constructor(props) {
		super(props)
		this.state = {
			content: "TBD"
		}
		this.showText = this.showText.bind(this);
		this.stopHere = this.stopHere.bind(this);
		this.doAlertOne = this.doAlertOne.bind(this);
	}
	
	componentDidMount() {
		setTimeout(this.doAlertOne,1000*40);
	}
	
	doAlertOne(){
		const winOpener = window.parent;
		winOpener.postMessage('func-alert',window.location.href);
		ReactDOM.render(
		  <AlertOne />,
		  document.getElementById('alerts')
		);
		document.getElementById('alerts').className='active';
	}
  
	showText(e) {
		const winOpener = window.parent;
		e.preventDefault();
		const cwrap = document.getElementById('main-content-wrapper');
		const items = cwrap.getElementsByTagName('div');
		let isActive = (e.currentTarget.classList.contains('active')) ? true : false;
		//console.log(e.currentTarget.classList);
		for(var i = 0; i < items.length; i++) {
			let theId = items[i].getAttribute('id');
			items[i].className = addRemoveClass(theId, 'active', 'remove');
			items[i].className = addRemoveClass(theId, 'in-active', 'remove');
			if(theId!==e.currentTarget.getAttribute('id') && !isActive){
				items[i].className = addRemoveClass(theId, 'in-active', 'add');
			}
		}
		if(!isActive) e.currentTarget.className = addRemoveClass(e.currentTarget.getAttribute('id'), 'active', 'add');
		
		if(!isActive) {
			if(e.currentTarget.getAttribute('id')==='func-info') {
				ReactDOM.render(
				  <ShowInfoWindow />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-info',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-report') {
				ReactDOM.render(
				  <ReportForm />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-report',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-community') {
				ReactDOM.render(
				  <Community />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-community',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-connect') {
				ReactDOM.render(
				  <ConnectPeople />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-connect',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-traffic') {
				ReactDOM.render(
				  <Traffic />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-traffic',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-appointment') {
				ReactDOM.render(
				  <Appointment />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-appointment',window.location.href);
			}
			else if(e.currentTarget.getAttribute('id')==='func-files') {
				ReactDOM.render(
				  <Loket />,
				  document.getElementById('textroot')
				);
				winOpener.postMessage('func-files',window.location.href);
			}
			document.getElementById('textroot').className='active';
		}
		else {
			ReactDOM.unmountComponentAtNode(document.getElementById('textroot'));
			document.getElementById('textroot').className='';
			winOpener.postMessage('remove',window.location.href);
		}
	}  
	
	stopHere() {
		window.alert('Nothing here yet');
	}
  
  render() {
    return (
		<div id="fullhead" className="clearfix">
			<header id="masthead" className="clearfix">
				<img src="logo-curacao.png" className="App-logo" alt="logo" />
			</header>
			
			<div id="main-content-wrapper">
				
				{/* info */}
				<div id="func-info" className="funcs" onClick={this.showText}>
					<img src="icon-file.png" alt="information" />
				</div>
				
				{/* report */}
				<div id="func-report" className="funcs" onClick={this.showText}>
					<img src="icon-pic.png" alt="report" />
				</div>
				
				{/* traffic */}
				<div id="func-traffic" className="funcs" onClick={this.showText}>
					<img src="icon-traffic.png" alt="traffic" />
				</div>
				
				{/* community */}
				<div id="func-community" className="funcs" onClick={this.showText}>
					<img src="icon-people.png" alt="community" />
				</div>
				
				{/* connect */}
				<div id="func-connect" className="funcs" onClick={this.showText}>
					<img src="icon-connect.png" alt="connect" />
				</div>
				
				{/* appointment */}
				<div id="func-appointment" className="funcs" onClick={this.showText}>
					<img src="icon-calendar.png" alt="appointment" />
				</div>
				
				{/* files */}
				<div id="func-files" className="funcs" onClick={this.showText}>
					<img src="icon-print.png" alt="files" />
				</div>
				
				{/* Video */}
				<div id="func-video" className="funcs" onClick={this.stopHere}>
					<img src="icon-video.png" alt="videos" />
				</div>
				
			</div>
			
		</div>
    );
  }
}

class Loket extends React.Component {
	constructor(props){
		super(props)
		this.loadWhat = this.loadWhat.bind(this);
		this.openClose = this.openClose.bind(this);
	}
	
	loadWhat(e){
		//let theURL = 'http://localhost:3000/'+e.currentTarget.getAttribute('data-href')+'.html';
		let theURL = window.location.protocol + '//' + window.location.host + '/' + e.currentTarget.getAttribute('data-href')+'.html';
		console.log(e.currentTarget.getAttribute('rel'));
		if(e.currentTarget.getAttribute('rel')==='external' && e.currentTarget.getAttribute('data-href')!=='') window.open(e.currentTarget.getAttribute('data-href'));
		else if(e.currentTarget.getAttribute('data-href')!=='') {
			if(e.currentTarget.getAttribute('data-src')==='gobiernu') {
				ReactDOM.render(
				  <HtmlGobiernu theurl={theURL} whichTag='main' whichTagId='main' whichRemoveTag='script' whichRemoveTagId='all-of-them' />,
				  document.getElementById('textroot')
				)
			}
		}
		else {
			window.alert('This is just a sample and not complete')
		}
		//<div id="something" className="theContents" onClick={() => this.loadWhat('some link')}>clickme</div>
	}
	
	openClose(e){
		//const cwrap = document.getElementById('info-list');
		//const items = cwrap.getElementsByTagName('ul');
		e.stopPropagation();
		let el = e.currentTarget;
		let elActive = (el.classList.contains('active')) ? true : false;
		let children = el.children;
		let classArray = [], cIndex = 0, i = 0;
		console.log(e.currentTarget);
		if(!elActive){
			for (i = 0; i < children.length; i++) {
				classArray = children[i].getAttribute('class').split(" ");
				classArray.push('open');
				children[i].className = classArray.join(" ");
			}
			classArray = el.getAttribute('class').split(" ");
			classArray.push('active');
			el.className = classArray.join(" ");
			
		}
		else {
			for (i = 0; i < children.length; i++) {
				classArray = children[i].getAttribute('class').split(" ");
				cIndex = classArray.indexOf('open');
				if (cIndex !== -1) {
					classArray.splice(cIndex, 1);
				}
				children[i].className = classArray.join(" ");
			}
			classArray = el.getAttribute('class').split(" ");
			cIndex = classArray.indexOf('active');
			if (cIndex !== -1) {
				classArray.splice(cIndex, 1);
			}
			el.className = classArray.join(" ");
		}
		
	}
	
	render() {
		return (
			<div id="loket">
				<h1 className="contentHeader">Digital Counter</h1>
				<div className="theContents">
					<ul id="info-list" className="info-list">
						<li onClick={this.openClose} id="oneO" className="parent-list">Civil Affairs
							<ul className="child-list">
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-bewijs-inschrijving">Proof of registration</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-proof-life">Proof of life</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-geboorte">Registration of birth</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-inschrijven">Registration in civil registry</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-noodpaspoort">Emergency passport</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Etcetera</li>
							</ul>
						</li>
						<li onClick={this.openClose} className="parent-list">Business Permits
							<ul className="child-list">
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-horeca">Hospitality permit</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-standplaats">Pitch permit</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-werk">Work permit</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="loket-vent">Vent permit</li>
								<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Etcetera</li>
							</ul>
						</li>
						<li>Etcetera</li>
					</ul>
				</div>
			</div>
		)
	}
}

class AlertOne extends React.Component {
	constructor(props){
		super(props)
		this.getTheDate = this.getTheDate.bind(this);
		this.closeAlert = this.closeAlert.bind(this);
	}
	
	closeAlert(e){
		ReactDOM.unmountComponentAtNode(document.getElementById('alerts'));
		document.getElementById('alerts').className='';
	}
	
	getTheDate(when='') {
		var dat = new Date(); // for now
		var m = dat.getMonth()+1;
		var d = dat.getDate();
		var h = dat.getHours();
		var mm = dat.getMinutes();
		let months = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
		
		if(m<10) m = "0"+m;
		if(d<10) d = "0"+d;
		if(h<10) h = "0"+h;
		if(mm<10) mm = "0"+mm;
		
		return months[m] + " " + d + ", " + h + ":" + mm;
	}
	
	render() {
		return (
			<div className="weather-alert">
				<div className="close" onClick={this.closeAlert}></div>
				<div className="waImage"><img src="/code-orange.png" alt="Code Orange" /></div>
				<div className="waTime">
					{this.getTheDate()}
					<div className="waLink"><a href="http://www.meteo.cw" target="_blank" rel="noopener noreferrer">Find out more on meteo.cw</a></div>
				</div>
			</div>
		)
	}
}

class Appointment extends React.Component {
	constructor(props){
		super(props);
		this.state = {
		  startDate: moment(),
		  amountOfPeople: 1
		};
		this.handleDateChange = this.handleDateChange.bind(this);
		this.selectClick = this.selectClick.bind(this);
		this.optionSelect = this.optionSelect.bind(this);
		this.getTheDate = this.getTheDate.bind(this);
		this.isWeekday = this.isWeekday.bind(this);
	}
	
	selectClick(e) {
		var which = e.currentTarget.getAttribute('data-href');
		if(document.getElementById(which).classList.contains('active')){
			document.getElementById(which).className = addRemoveClass(which, 'active', 'remove')
		}
		else {
			document.getElementById(which).className = addRemoveClass(which, 'active', 'add');
		}
		
	}
	optionSelect(e) {
		//console.log(e.currentTarget.parentElement.parentElement);
		var whichSelect = e.currentTarget.parentElement.parentElement.getAttribute('data-href');
		var whichHelper = e.currentTarget.parentElement.parentElement.getAttribute('id');
		var newVal = e.currentTarget.getAttribute('data-href');
		[].forEach.call(  document.querySelectorAll('#' + whichSelect + ' option')  , function(elm){
			//console.log(elm.value + ' ' + elm.selected);
			if(elm.value === newVal) {
				elm.selected = true;
				document.getElementById(whichSelect+'Receiver').innerHTML = elm.text;
			}
		});
		document.getElementById(whichHelper).className = addRemoveClass(whichHelper, 'active', 'remove')
		
		if (whichSelect==='maType') document.getElementById('choseSomething1').className = addRemoveClass('choseSomething1', 'active', 'add');
		if (whichSelect==='maPeople') {
			this.setState({startDate: this.getTheDate('forPicker')});
			this.setState({amountOfPeople: newVal});
			document.getElementById('choseSomething2').className = addRemoveClass('choseSomething2', 'active', 'add');
		}
	}
	
	
	getTheDate(when='') {
		var dat = new Date(); // for now
		var da = new Date(dat.getTime() + 1000*60*60*24*21);
		var y = da.getFullYear();
		var m = da.getMonth()+1;
		var d = da.getDate();
		let months = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
		if(when==='') return (months[m] + " " + d);
		else {
			if(m<10) m = "0"+m;
			if(d<10) d = "0"+d;
			return (moment(y + '-' + m + '-' + d + '8:00', 'YYYY-MM-DD HH:mm'));
		}
	}
	
	handleDateChange(date) {
		this.setState({
		  startDate: date
		});
		document.getElementById('choseSomething3').className = addRemoveClass('choseSomething3', 'active', 'add');
	}
	
	isWeekday (date) {
		const day = date.day()
		return day !== 0 && day !== 6
	}
	
	
	render() {
		return (
			<div>
				<h1 className="contentHeader">Appointment</h1>
				<div id="msgRoot"></div>
				<div className="theContents">
					<p className="intro-text">
						Make an appointment to apply for or renew your passport, sedula, or driver's license.
					</p>
					<div className="makeAppointment">
						<form id="appointmentForm">
							
							<div className="form-group">
								<label htmlFor="maType">
									I would like an appointment for *
									<div id="maTypeReceiver" className="fhReceiver" data-href="maTypeHelper" onClick={this.selectClick}>
										Select an option
									</div>
								</label>
								<select name="maType" id="maType" className="formElement formElementHidden">
									<option value="">Select an option</option>
									<option value="passport">Passport</option>
									<option value="sedula">Sedula</option>
									<option value="drivers_license">Driver's License</option>
								</select>
								<div id="maTypeHelper" className="form-helper selectHelper clearfix" data-href="maType">
									<div className="inner">
										<span className="fhOption" onClick={this.optionSelect} data-href="passport">Passport</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="sedula">Sedula</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="drivers_license">Driver's License</span>
									</div>
								</div>
							</div>
							
							
							<div className="form-group choseSomething" id="choseSomething1">
								<label htmlFor="maPeople">
									Amount of people *
									<div id="maPeopleReceiver" className="fhReceiver" data-href="maPeopleHelper" onClick={this.selectClick}>
										1
									</div>
								</label>
								<select name="maPeople" id="maPeople" className="formElement formElementHidden" defaultValue="1">
									<option value="">Select an option</option>
									<option value="1">1</option>
									<option value="2">2</option>
									<option value="3">3</option>
									<option value="4">4</option>
									<option value="5">5</option>
									<option value="6">6</option>
								</select>
								<div id="maPeopleHelper" className="form-helper selectHelper clearfix" data-href="maPeople">
									<div className="inner">
										<span className="fhOption" onClick={this.optionSelect} data-href="1">1</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="2">2</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="3">3</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="4">4</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="5">5</span>
										<span className="fhOption" onClick={this.optionSelect} data-href="6">6</span>
									</div>
								</div>
							</div>
							
							<div className="form-group choseSomething" id="choseSomething2">
								<label htmlFor="maDate">
									Select a date *<br />
									<span className="formInfo active">
										Your first available date is {this.getTheDate()}.<br />
										The time per person is 10 minutes. You chose {this.state.amountOfPeople} people.
									</span>
								</label>
								<DatePicker
									selected={this.state.startDate}
									onChange={this.handleDateChange}
									filterDate={this.isWeekday}
									locale="en-gb"
									minDate={this.getTheDate('forPicker')}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={this.state.amountOfPeople * 10}
									minTime={moment().hours(8).minutes(0)}
									maxTime={moment().hours(15).minutes(0)}
									excludeTimes={[
										moment().hours(12).minutes(0),
										moment().hours(12).minutes(10),
										moment().hours(12).minutes(20),
										moment().hours(12).minutes(30),
										moment().hours(12).minutes(40),
										moment().hours(12).minutes(50)
									]}
									dateFormat="MMMM D, YYYY HH:mm"
									preventOpenOnFocus />
								<input type="text" name="maDate" id="maDate" defaultValue="" className="formElement formElementHidden" />
							</div>
							
						
							<div className="form-group choseSomething" id="choseSomething3" >
								<span className="formInfo active">
									And then people need to fill out their personal data which is pre-filled if they're logged in.
								</span>
								<div id="submitForm" onClick={() => window.alert('Submitted')}>Make apppointment</div>
							</div>
							
						</form>
					</div>
				</div>
			</div>
		)
	}
}

class Traffic extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pos1: 0,
			pos2: 0,
			pos3: 0,
			pos4: 0,
			isLoading: true,
		}
		this.dragMouseDown = this.dragMouseDown.bind(this);
		this.elementDrag = this.elementDrag.bind(this);
		this.closeDragElement = this.closeDragElement.bind(this);
		this.dragTouchStart = this.dragTouchStart.bind(this);
		this.elementTouchDrag = this.elementTouchDrag.bind(this);
		this.closeTouchElement = this.closeTouchElement.bind(this);
		this.clickEx = this.clickEx.bind(this);
	}
	
	componentDidMount() {
		
	}
	
	dragMouseDown(e) {
		e = e || window.event;
		e.persist();
		e.stopPropagation();
		e.preventDefault();
		//console.log('bla');
		console.log(e.clientX + " , " + e.clientY);
		// get the mouse cursor position at startup:
		this.setState({ pos3: e.clientX, pos4: e.clientY  });
		document.onmouseup = this.closeDragElement;
		// call a function whenever the cursor moves:
		document.onmousemove = this.elementDrag;
	}
	elementDrag(e) {
		e = e || window.event;
		e.preventDefault();
		// calculate the new cursor position:
		this.setState({ 
			pos1: this.state.pos3 - e.clientX,
			pos2: this.state.pos4 - e.clientY,
			pos3: e.clientX,
			pos4: e.clientY
		});
		// set the elements' new positions:
		if(document.getElementById("trafficMap").offsetTop - this.state.pos2 < 0 && document.getElementById("trafficMap").offsetTop - this.state.pos2 > ((document.getElementById("trafficMap").offsetHeight - window.innerHeight + document.getElementById('fullhead').offsetHeight)*-1)) {
			document.getElementById("trafficMap").style.top = (document.getElementById("trafficMap").offsetTop - this.state.pos2) + "px";
			document.getElementById("trafficData").style.top = (document.getElementById("trafficData").offsetTop - this.state.pos2) + "px";
			document.getElementById("trafficDragger").style.top = (document.getElementById("trafficDragger").offsetTop - this.state.pos2) + "px";
		}
		if(document.getElementById("trafficMap").offsetLeft - this.state.pos1 < 0  && document.getElementById("trafficMap").offsetLeft - this.state.pos1 > (document.getElementById("trafficMap").offsetWidth - window.innerWidth)*-1) {
			document.getElementById("trafficMap").style.left = (document.getElementById("trafficMap").offsetLeft - this.state.pos1) + "px";
			document.getElementById("trafficData").style.left = (document.getElementById("trafficData").offsetLeft - this.state.pos1) + "px";
			document.getElementById("trafficDragger").style.left = (document.getElementById("trafficDragger").offsetLeft - this.state.pos1) + "px";
		}
	}
	closeDragElement() {
		/* stop moving when mouse button is released:*/
		document.onmouseup = null;
		document.onmousemove = null;
	}
	
	// for the touchy interfaces
	dragTouchStart(e) {
		e = e || window.event;
		var touch = e.touches[0];
		//console.log(touch);
		var x = touch.pageX;
		var y = touch.pageY;
		this.setState({ pos3: x, pos4: y  });
		document.ontouchend = this.closeTouchElement;
		// call a function whenever the cursor moves:
		document.ontouchmove = this.elementTouchDrag;
	}
	elementTouchDrag(e) {
		e = e || window.event;
		var touch = e.touches[0];
		//e.preventDefault();
		// calculate the new cursor position:
		this.setState({ 
			pos1: this.state.pos3 - touch.pageX,
			pos2: this.state.pos4 - touch.pageY,
			pos3: touch.pageX,
			pos4: touch.pageY
		});
		console.log(touch.pageX + " , " + touch.pageY);
		// set the elements' new positions within boundaries:
		if(document.getElementById("trafficMap").offsetTop - this.state.pos2 < 0 && document.getElementById("trafficMap").offsetTop - this.state.pos2 > ((document.getElementById("trafficMap").offsetHeight - window.innerHeight + document.getElementById('fullhead').offsetHeight)*-1)) {
			document.getElementById("trafficMap").style.top = (document.getElementById("trafficMap").offsetTop - this.state.pos2) + "px";
			document.getElementById("trafficData").style.top = (document.getElementById("trafficData").offsetTop - this.state.pos2) + "px";
			document.getElementById("trafficDragger").style.top = (document.getElementById("trafficDragger").offsetTop - this.state.pos2) + "px";
		}
		if(document.getElementById("trafficMap").offsetLeft - this.state.pos1 < 0  && document.getElementById("trafficMap").offsetLeft - this.state.pos1 > (document.getElementById("trafficMap").offsetWidth - window.innerWidth)*-1) {
			document.getElementById("trafficMap").style.left = (document.getElementById("trafficMap").offsetLeft - this.state.pos1) + "px";
			document.getElementById("trafficData").style.left = (document.getElementById("trafficData").offsetLeft - this.state.pos1) + "px";
			document.getElementById("trafficDragger").style.left = (document.getElementById("trafficDragger").offsetLeft - this.state.pos1) + "px";
		}
	}
	closeTouchElement() {
		/* stop moving when mouse button is released:*/
		document.ontouchend = null;
		document.ontouchmove = null;
	}
	
	clickEx(which,e){
		//var elLeft = e.currentTarget.offsetLeft;
		//var elTop = e.currentTarget.offsetTop;
		if(e.currentTarget.classList.contains('active')) {
			e.currentTarget.className='exes clearfix';
			if(which==='one') {
				ReactDOM.unmountComponentAtNode(document.getElementById('ex1'));
				document.getElementById('ex1').innerHTML = 'i';
			}
			else {
				ReactDOM.unmountComponentAtNode(document.getElementById('ex2'));
				document.getElementById('ex2').innerHTML = 'i';
			}
			
		}
		else {
			e.currentTarget.className='exes clearfix active';
			if(which==='one') {
				ReactDOM.render(
				  <ExContentOne />,
				  document.getElementById('ex1')
				);
			}
			else {
				ReactDOM.render(
				  <ExContentTwo />,
				  document.getElementById('ex2')
				);
			}
		}
	}
	
	render() {
		return (
			<div>
				<div id="trafficContainer">
					<div id="trafficMap"><img src="/map.jpg" alt="map" /></div>
					<div id="trafficData"><img src="/traffic-data.png" alt="map" /></div>
					<div id="trafficDragger" onMouseDown={this.dragMouseDown} onTouchStart={this.dragTouchStart}>
						<div id="thisEx1" className="exes clearfix" style={{top:'1225px', left:'1085px'}} onClick={(e) => this.clickEx('one',e)}><div id="ex1" className="ex">i</div></div>
						<div id="thisEx2" className="exes clearfix" style={{top:'1409px', left:'937px'}} onClick={(e) => this.clickEx('two',e)}><div id="ex2" className="ex">i</div></div>
					</div>
					
				</div>
			</div>
		)
	}
}

class ExContentOne extends React.Component {
	constructor(props){
		super(props)
		this.getCurrentTime = this.getCurrentTime.bind(this);
	}
	
	getCurrentTime() {
		var dat = new Date(); // for now
		var da = new Date(dat.getTime() - 1000*60*30);
		var h = da.getHours(); // => 9
		var d = da.getMinutes(); // =>  30
		if(h<10) h = "0"+h;
		if(d<10) d = "0"+d;
		return ('@ ' + h + ":" + d);
	}
	
	render() {
		return (
			<div>
				<div className="accidentTitle">Accident/Collision 2 cars {this.getCurrentTime()}</div>
				<div className="accidentDescription">Rijkseenheid Boulevard / Erieweg going North. Pile-up at traffic lights.</div>
				<div className="accidentPolice">Police on scene: yes</div>
				<div className="accidentAmbulance">Ambulance on scene: not needed</div>
				<div className="accidentResults">Lanes closed: 1 / Lanes open: 1 / traffic buildup both directions</div>
			</div>
		)
	}
}

class ExContentTwo extends React.Component {
	constructor(props){
		super(props)
		this.getCurrentTime = this.getCurrentTime.bind(this);
	}
	
	getCurrentTime() {
		var dat = new Date(); // for now
		var da = new Date(dat.getTime() - 1000*60*25);
		var h = da.getHours(); // => 9
		var d = da.getMinutes(); // =>  30
		if(h<10) h = "0"+h;
		if(d<10) d = "0"+d;
		return ('@ ' + h + ":" + d);
	}
	
	render() {
		return (
			<div>
				<div className="accidentTitle">Accident/Collision 1 car  {this.getCurrentTime()}</div>
				<div className="accidentDescription">Pres Romulo Betancout Blvd going West. Driver unwell, car hit brick wall</div>
				<div className="accidentPolice">Police on scene: yes</div>
				<div className="accidentAmbulance">Ambulance on scene: on the way</div>
				<div className="accidentResults">Lanes closed: 0 / Lanes open: 3 / traffic buildup both directions</div>
			</div>
		)
	}
}

class ConnectPeople extends React.Component {
	constructor(props) {
		super(props)
		this.closeEl = this.closeEl.bind(this);
		this.openEvent = this.openEvent.bind(this);
	}
	
	closeEl(e) {
		e.preventDefault();
		let pEl = e.currentTarget.parentNode;
		pEl.className = 'eventPreview clearfix closed';
	}
	
	openEvent(e){
		e.preventDefault();
		let which = e.currentTarget.getAttribute('data-href');
		document.getElementById('msgRoot').className="active";
		if(which==='neighborhood'){
			ReactDOM.render(
			  <OpenNeighborhood />,
			  document.getElementById('msgRoot')
			);
		}
		else {
			ReactDOM.render(
			  <OpenKickstart />,
			  document.getElementById('msgRoot')
			);
		}
	}
	
	render() {
		return (
			<div>
				<h1 className="contentHeader">Community</h1>
				<div id="msgRoot"></div>
				<div className="theContents">
					
					<div className="events">
						<div id="eventsIntro" className="intro-text">
							Below you'll find the events posted in your neighborhood group, as well as 
							the most popular events in your special interest groups.
						</div>
						<div className="eventPreviews">
							<div className="eventPreview clearfix">
								<div className="close" onClick={this.closeEl}></div>
								<div className="epDetails" data-href="neighborhood" onClick={this.openEvent}>
									<div className="epTitle">Cleaning up Stuyvesantstraat &amp; surroundings</div>
									<div className="epLocation">
										Mundu Nobo &middot;
										<div className="epDates">
											<span className="epFrom">Oct 13 &middot; 07:00</span> - <span className="epTill">11:00</span>
										</div>
									</div>
									<div className="epDescription">
										We need a couple people to help us clean up the sidewalks of the Stuyvesantstraat ... <span className="epAttending">12 going</span>
									</div>
								</div>
							</div>
							
							<div className="eventPreview clearfix">
								<div className="close" onClick={this.closeEl}></div>
								<div className="epDetails" data-href="kickstart" onClick={this.openEvent}>
									<div className="epTitle">Kickstart Cura&ccedil;ao Innovathon</div>
									<div className="epLocation">
										PWFC Building  &middot;
										<div className="epDates">
											<span className="epFrom">Oct 19 &middot; 08:00</span> - <span className="epTill">20:00</span>
										</div>
									</div>
									<div className="epDescription">
										Apply NOW to join the KICKSTART CURA&Ccedil;AO INNOVATHON on ... <span className="epAttending">18 going</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		)
	}
}

class OpenKickstart extends React.Component {
	constructor(props) {
		super(props)
		this.closeMsg = this.closeMsg.bind(this);
		this.attendEvent = this.attendEvent.bind(this);
	}
	
	closeMsg(e){
		ReactDOM.unmountComponentAtNode(document.getElementById('msgRoot'));
		document.getElementById('msgRoot').className='';
	}
	
	attendEvent(e) {
		let el = e.currentTarget;
		let elNr = document.getElementById('attendNum');
		el.className='eventAttend attending';
		el.innerHTML = 'Attending';
		elNr.innerHTML = '11';
	}
	
	render() {
		return (
			<div>
				<div className="eventHeader clearfix">
					<div className="close" onClick={this.closeMsg}></div>
					<div className="eventHDetail">
						<div className="eventHTitle">Kickstart Cura&ccedil;ao Innovathon</div>
						<div className="eventHLocation">
							PWFC Building &middot;
							<div className="eventHDates">
								<span className="eventHFrom">Oct 19 &middot; 08:00</span> - <span className="eventHTill">20:00</span>
							</div>
						</div>
					</div>
				</div>
				<div className="eventBlock">
					<div className="eventContent">
						<p>
							Apply NOW to join the KICKSTART CURAÇAO INNOVATHON on October 19th 2018 at PWFC!
						</p>
						<p>
							@ Kickstart Cura&ccedil;ao, teams will come up with ideas and work on disruptive solutions 
							and tangible change, needed to get our Economy growing and make life better for everyone. 
						</p>
						<p>
							The 'Kickstart Cura&ccedil;ao Innovathon' is an Ideation Hackathon organized by CITI in collaboration 
							with the Ministry of Economic Development of Cura&ccedil;ao.
						</p>
						<p>
							To learn more or to register visit <a href="http://www.citi.cw" target="_blank" rel="noopener noreferrer">www.citi.cw</a>.<br />
							Let's rock this!
						</p>
					</div>
					<div className="eventAttending">
						<h3>Attending</h3>
						<div className="eventAAvatars clearfix">
							<div className="eventAAvatar"><img src="avatar-1.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-2.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-3.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-4.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-5.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-6.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-7.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-8.jpg" alt="" /></div>
						</div>
						<div className="eventAAvatarsMore">...and <span id="attendNum">10</span> more</div>
					</div>
					<div className="eventAttend" onClick={this.attendEvent}>Join this event</div>
				</div>
			</div>
		)
	}
}


class OpenNeighborhood extends React.Component {
	constructor(props) {
		super(props)
		this.closeMsg = this.closeMsg.bind(this);
		this.attendEvent = this.attendEvent.bind(this);
	}
	
	closeMsg(e){
		ReactDOM.unmountComponentAtNode(document.getElementById('msgRoot'));
		document.getElementById('msgRoot').className='';
	}
	
	attendEvent(e) {
		let el = e.currentTarget;
		let elNr = document.getElementById('attendNum');
		el.className='eventAttend attending';
		el.innerHTML = 'Attending';
		elNr.innerHTML = '5';
	}
	
	render() {
		return (
			<div>
				<div className="eventHeader clearfix">
					<div className="close" onClick={this.closeMsg}></div>
					<div className="eventHDetail">
						<div className="eventHTitle">Cleaning up Stuyvesantstraat &amp; surroundings</div>
						<div className="eventHLocation">
							Mundu Nobo &middot;
							<div className="eventHDates">
								<span className="eventHFrom">Oct 13 &middot; 07:00</span> - <span className="eventHTill">11:00</span>
							</div>
						</div>
					</div>
				</div>
				<div className="eventBlock">
					<div className="eventContent">
						<p>
							We need a couple people to help us clean up the sidewalks of the Stuyvesantstraat towards the Cura&ccedil;ao Museum.
							There's a lot of grass and weeds growing out of the sidewalks and the gutter and there are some trees that can use pruning.
							We'll bring something to eat and drink and if we have enough people and tools it should be done quickly.
						</p>
						<p>
							If you have tools or a pickup to haul everything away, please bring them.
						</p>
					</div>
					<div className="eventAttending">
						<h3>Attending</h3>
						<div className="eventAAvatars clearfix">
							<div className="eventAAvatar"><img src="avatar-1.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-2.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-3.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-4.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-5.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-6.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-7.jpg" alt="" /></div>
							<div className="eventAAvatar"><img src="avatar-8.jpg" alt="" /></div>
						</div>
						<div className="eventAAvatarsMore">...and <span id="attendNum">4</span> more</div>
					</div>
					<div className="eventAttend" onClick={this.attendEvent}>Join this event</div>
				</div>
			</div>
		)
	}
}


class Community extends React.Component {
	constructor(props) {
		super(props)
		this.closeEl = this.closeEl.bind(this);
		this.openMsg = this.openMsg.bind(this);
	}
	
	closeEl(e) {
		e.preventDefault();
		let pEl = e.currentTarget.parentNode;
		let num = document.getElementById('nmNum').innerHTML * 1 - 1;
		pEl.className = 'messagePreview clearfix closed';
		document.getElementById('nmNum').innerHTML = num;
		if(num===1) document.getElementById('numS').innerHTML = '';
			else document.getElementById('numS').innerHTML = 's';
	}
	
	openMsg(e){
		e.preventDefault();
		let which = e.currentTarget.getAttribute('data-href');
		document.getElementById('msgRoot').className="active";
		if(which==='matthelton'){
			ReactDOM.render(
			  <OpenMsgMat />,
			  document.getElementById('msgRoot')
			);
		}
		else {
			ReactDOM.render(
			  <OpenMsgVanessa />,
			  document.getElementById('msgRoot')
			);
		}
	}
	
	render() {
		return (
			<div>
				<h1 className="contentHeader">Connect</h1>
				<div id="msgRoot"></div>
				<div className="theContents">
				
					<div className="messages">
						<div id="newMessages">
							You have <span id="nmNum">2</span> new message<span id="numS">s</span>
						</div>
						<div id="messagesPreview">
							<div className="messagePreview clearfix">
								<div className="close" onClick={this.closeEl}></div>
								<div className="mpAvatar"><img src="/avatar-2.jpg" alt="Matthelton Schmijtens" /></div>
								<div className="mpDetails" data-href="matthelton" onClick={this.openMsg}>
									<div className="mpFrom">Matthelton Martina <span className="mpTime">11 Oct 2018, 09:12</span></div>
									<div className="mpMessage">Fecha di registrashon pa e mishon komersial pa Colombia...</div>
								</div>
							</div>
							<div className="messagePreview clearfix">
								<div className="close" onClick={this.closeEl}></div>
								<div className="mpAvatar"><img src="/avatar-1.jpg" alt="Vanessa Maria" /></div>
								<div className="mpDetails" data-href="vanessa" onClick={this.openMsg}>
									<div className="mpFrom">Vanessa Maria <span className="mpTime">10 Oct 2018, 11:48</span></div>
									<div className="mpMessage">Awe den oranan di mainta h&aacute;bitantenan di Monta&ntilde;a a...</div>
								</div>
							</div>
						</div>
					</div>
					
				</div>
			</div>
		)
	}
	
}

class OpenMsgMat extends React.Component {
	constructor(props) {
		super(props)
		this.closeMsg = this.closeMsg.bind(this);
	}
	
	closeMsg(e){
		ReactDOM.unmountComponentAtNode(document.getElementById('msgRoot'));
		document.getElementById('msgRoot').className='';
	}
	
	render() {
		return (
			<div>
				<div className="msgHeader clearfix">
					<div className="close" onClick={this.closeMsg}></div>
					<div className="msgHAvatar"><img src="/avatar-2.jpg" alt="Matthelton Martina" /></div>
					<div className="msgHDetail">
						<div className="msgHName">Matthelton Martina</div>
					</div>
				</div>
				<div className="msgBlock">
					<div className="msgLeft">
						<div className="msgTime">11 Oct 2018, 09:12</div>
						<div className="msgContent">
							Fecha di registrashon pa e mishon komersial pa Colombia a keda prolonga<br />
							Empresarionan interesa awor tin te ku djaluna dia 8 di &ograve;ktober 
							pr&oacute;ksimo pa registr&aacute;.
						</div>
					</div>
				</div>
				<div className="msgReply">
					<form>
						<textarea cols="25" rows="3" name="reply"></textarea>
					</form>
				</div>
			</div>
		)
	}
}

class OpenMsgVanessa extends React.Component {
	constructor(props) {
		super(props)
		this.closeMsg = this.closeMsg.bind(this);
	}
	
	closeMsg(e){
		ReactDOM.unmountComponentAtNode(document.getElementById('msgRoot'));
		document.getElementById('msgRoot').className='';
	}
	
	render() {
		return (
			<div>
				<div className="msgHeader clearfix">
					<div className="close" onClick={this.closeMsg}></div>
					<div className="msgHAvatar"><img src="/avatar-1.jpg" alt="Vanessa Maria" /></div>
					<div className="msgHDetail">
						<div className="msgHName">Vanessa Maria</div>
					</div>
				</div>
				<div className="msgBlock">
					<div className="msgRight">
						<div className="msgTime">10 Oct 2018, 12:12</div>
						<div className="msgContent">
							Bon dia Vanessa, <br />
							Bo tabatin aktividatnan interessante na mainta?
						</div>
					</div>
					<div className="msgLeft">
						<div className="msgTime">10 Oct 2018, 13:48</div>
						<div className="msgContent">
							Awe den oranan di mainta h&aacute;bitantenan di Monta&ntilde;a a despleg&aacute; 
							nan talentonan &aacute;rtistiko i krea un obra di arte ku ta símbolo di e bario 
							i nan orguyo komo h&aacute;bitante di nan bario. Aki nos por apresi&aacute; e 
							bunita resultado.
						</div>
					</div>
				</div>
				<div className="msgReply">
					<form>
						<textarea cols="25" rows="3" name="reply"></textarea>
					</form>
				</div>
			</div>
		)
	}
}

class ShowInfoWindow extends React.Component {
	constructor(props) {
		super(props)
		this.loadWhat = this.loadWhat.bind(this);
		this.openClose = this.openClose.bind(this);
	}
	
	loadWhat(e){
		//let theURL = 'http://localhost:3000/'+e.currentTarget.getAttribute('data-href')+'.html';
		let theURL = window.location.protocol + '//' + window.location.host + '/' + e.currentTarget.getAttribute('data-href')+'.html';
		console.log(e.currentTarget.getAttribute('rel'));
		if(e.currentTarget.getAttribute('rel')==='external' && e.currentTarget.getAttribute('data-href')!=='') window.open(e.currentTarget.getAttribute('data-href'));
		else if(e.currentTarget.getAttribute('data-href')!=='') {
			if(e.currentTarget.getAttribute('data-src')==='gobiernu') {
				ReactDOM.render(
				  <HtmlGobiernu theurl={theURL} />,
				  document.getElementById('textroot')
				)
			}
			else if(e.currentTarget.getAttribute('data-src')==='parlamento'){
				ReactDOM.render(
				  <HtmlParlamento theurl={theURL} />,
				  document.getElementById('textroot')
				)
			}
		}
		else {
			window.alert('This is just a sample and not complete')
		}
		//<div id="something" className="theContents" onClick={() => this.loadWhat('some link')}>clickme</div>
	}
	
	openClose(e){
		//const cwrap = document.getElementById('info-list');
		//const items = cwrap.getElementsByTagName('ul');
		e.stopPropagation();
		let el = e.currentTarget;
		let elActive = (el.classList.contains('active')) ? true : false;
		let children = el.children;
		let classArray = [], cIndex = 0, i = 0;
		console.log(e.currentTarget);
		if(!elActive){
			for (i = 0; i < children.length; i++) {
				classArray = children[i].getAttribute('class').split(" ");
				classArray.push('open');
				children[i].className = classArray.join(" ");
			}
			classArray = el.getAttribute('class').split(" ");
			classArray.push('active');
			el.className = classArray.join(" ");
			
		}
		else {
			for (i = 0; i < children.length; i++) {
				classArray = children[i].getAttribute('class').split(" ");
				cIndex = classArray.indexOf('open');
				if (cIndex !== -1) {
					classArray.splice(cIndex, 1);
				}
				children[i].className = classArray.join(" ");
			}
			classArray = el.getAttribute('class').split(" ");
			cIndex = classArray.indexOf('active');
			if (cIndex !== -1) {
				classArray.splice(cIndex, 1);
			}
			el.className = classArray.join(" ");
		}
		
	}
	
	render() {
		return (
			<div>
				<h1 className="contentHeader">Information</h1>
				<div className="theContents">
					<ul id="info-list" className="info-list">
						<li onClick={this.openClose} id="oneO" className="parent-list">Government
							<ul className="child-list">
								<li onClick={this.openClose} id="oneOne" className="parent-list">Themes
									<ul className="child-list">
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="bibienda-ambiente-di-bida">Society</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="hubentut-ensenansa">Youth &amp; Education</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="salubridat-kuido">Healthcare</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="labor-negoshi">Work &amp; Business</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="imigrashon-emigrashon">Immigration</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Legal</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Tourism</li>
									</ul>
								</li>
								<li onClick={this.openClose} id="oneTwo" className="parent-list">Departments
									<ul className="child-list">
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="hustisia">Justice</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="finansa">Finance</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="asuntunan-general-i-prome-minister">General Affairs</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Health, Environment, &amp; Nature</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Economic Development</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Education, Science, Culture, &amp; Sports</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Infrastructure</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Governance, Planning ,&amp; Servicec</li>
										<li onClick={this.loadWhat} data-src="gobiernu" data-href="">Social Development, Labor, &amp; Welfare</li>
									</ul>
								</li>
								<li onClick={this.loadWhat} rel="external" data-href="https://www.gobiernu.cw/pap/notisia/">News &amp; Press Releases</li>
							</ul>
						</li>
						<li onClick={this.openClose} className="parent-list">Parliament
							<ul className="child-list">
								<li onClick={this.openClose} className="parent-list">Our Parliament
									<ul className="child-list">
										<li onClick={this.loadWhat} data-src="parlamento" data-href="prosedimento">Procedures &amp; Tasks</li>
										<li onClick={this.loadWhat} data-src="parlamento" data-href="kompetensianan_282">Rights</li>
										<li onClick={this.loadWhat} data-src="parlamento" data-href="relashonnan-eksterno_3100">External Affairs</li>
										<li rel="external">Agenda</li>
									</ul>
								</li>
								<li onClick={this.openClose} className="parent-list">Organization
									<ul className="child-list">
										<li onClick={this.loadWhat} data-src="parlamento" data-href="">Registry</li>
										<li onClick={this.loadWhat} data-src="parlamento" data-href="">Committees</li>
										<li onClick={this.loadWhat} data-src="parlamento" data-href="">History</li>
									</ul>
								</li>
								<li rel="external">News &amp; Press Releases</li>
							</ul>
						</li>
						<li>Etcetera</li>
					</ul>
				</div>
				
			</div>
		)
	}
	
}

class ReportForm extends React.Component {
	constructor(props) {
		super(props)
		this.policeLink = this.policeLink.bind(this);
		this.doHelp = this.doHelp.bind(this);
		this.snapPicture = this.snapPicture.bind(this);
	}	
	
	policeLink(e) {
		e.preventDefault();
		window.alert('Link to police numbers');
	}
	
	doHelp(e){
		let theFor = e.currentTarget.getAttribute('for');
		document.getElementById(theFor+'Help').className = addRemoveClass(theFor+'Help', 'active', 'add');
	}
	
	snapPicture(e) {
		document.getElementById('snappedPicture').innerHTML = '<img src="/pothole.jpg" />';
		window.alert('snapped');
	}
	
	render() {
		return (
			<div>
				<h1 className="contentHeader">Report</h1>
				<div id="reportForm" className="theContents">
					<form id="theReportForm">
						<div className="alert">
							For emergencies, please call <a href="tel:911">911</a> and if you need to report a crime, please call the <a href="http://www.polis.cw" target="_blank" rel="noreferrer noopener" onClick={this.policeLink}>police</a>. For less urgent reports you can use the form below.
						</div>
						<div className="form-group">
							<label htmlFor="what">
								I would like to report *
							</label>
							<select name="what" id="what" className="formElement">
								<option value="">Please choose one</option>
								<option value="pothole">Pothole</option>
								<option value="garbage">Garbage in the street</option>
								<option value="graffiti">Graffiti</option>
								<option value="vandalism">Vandalism</option>
								<option value="animal">Animal abuse</option>
								<option value="other">Other</option>
							</select>
						</div>
						
						<div className="form-group">
							<label htmlFor="where" className="hasHelp" onClick={this.doHelp}>
								The location or address
							</label>
							<div id="whereHelp" className="formInfo">
								If you log in to the app you can provide your exact location with your GPS.
							</div>
							<input type="text" name="where" id="where" className="formElement" />
						</div>
						
						<div className="form-group">
							<label>Snap a picture with your phone</label>
							<div id="snapPicture" onClick={this.snapPicture}>Click to open camera</div>
							<div id="snappedPicture"></div>
						</div>
						
						<div className="form-group">
							<label htmlFor="extrainfo" className="hasHelp" onClick={this.doHelp}>
								Any extra information you'd like to share. 
							</label>
							<div id="extrainfoHelp" className="formInfo">
								You can add your name and contact details if you like but it's not mandatory.
							</div>
							<textarea name="extrainfo" id="extrainfo" cols="30" rows="5" className="formElement"></textarea>
						</div>
						
						<div className="form-group">
							<div id="submitForm" onClick={() => window.alert('Submitted')}>Submit</div>
						</div>
					</form>
				</div>
			</div>
		)
	}
}

class HtmlGobiernu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
		content: "TBD",
		isLoading: true,
		whichTag: 'main',
		whichTagId: 'main-content',
		whichRemoveTag: '',
		whichRemoveTagId: ''
    }
  }	

  componentWillMount() {
	if (typeof this.props.whichTag !== 'undefined') this.setState({whichTag: this.props.whichTag});
	if (typeof this.props.whichTagId !== 'undefined') this.setState({whichTagId: this.props.whichTagId});
	if (typeof this.props.whichRemoveTag !== 'undefined') this.setState({whichRemoveTag: this.props.whichRemoveTag});
	if (typeof this.props.whichRemoveTagId !== 'undefined') this.setState({whichRemoveTagId: this.props.whichRemoveTagId});
	//this.loadData('https://www.gobiernu.cw/pap/themanan/bibienda-ambiente-di-bida/');
	this.loadData(this.props.theurl);
  }

  loadData = (url) => {
	//const proxyurl = "https://cors-anywhere.herokuapp.com/";
    //fetch(proxyurl + url)
	fetch(url)
      .then(function (response) {
        //console.log(url + " -> " + response.ok);
		//console.log(response.text())
        return response.text();
      })
      .then(function (data) {
        //console.log("data: ", data);
        this.setState({ content: data, isLoading: false  });
      }.bind(this))
      .catch(function (err) {
        console.log("failed to load ", url, err.stack);
      });
  }
  
  render() {
	const { isLoading } = this.state;
	if(isLoading){
		return <p>Loading...</p>
	}
	else {
		let html = this.state.content;
		//console.log(html);
		return (
			<div>
				<h1 className="contentHeader">Information</h1>
				<div className="theContents"><GetDOM html={html} tag={this.state.whichTag} tagid={this.state.whichTagId} removetag={this.state.whichRemoveTag} removetagid={this.state.whichRemoveTagId} /></div>;
			</div>
		)
	}
  }
}

class HtmlParlamento extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
		content: "TBD",
		isLoading: true,
    }
  }	

  componentWillMount() {
    //this.loadData('https://www.gobiernu.cw/pap/themanan/bibienda-ambiente-di-bida/');
	this.loadData(this.props.theurl);
  }

  loadData = (url) => {
	//const proxyurl = "https://cors-anywhere.herokuapp.com/";
    //fetch(proxyurl + url)
	fetch(url)
      .then(function (response) {
        //console.log(url + " -> " + response.ok);
		//console.log(response.text())
        return response.text();
      })
      .then(function (data) {
        //console.log("data: ", data);
        this.setState({ content: data, isLoading: false  });
      }.bind(this))
      .catch(function (err) {
        console.log("failed to load ", url, err.stack);
      });
  }
  
  render() {
	const { isLoading } = this.state;
	if(isLoading){
		return <p>Loading...</p>
	}
	else {
		let html = this.state.content;
		//console.log(html);
		return (
			<div>
				<h1 className="contentHeader">Information</h1>
				<div className="theContents"><GetDOM html={html} tag='div' tagid='content' removetag='div' removetagid='kruimelspoor' /></div>;
			</div>
		)
	}
  }
}

class GetDOM extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			contents: "TBD",
		}
		this.handleClick = this.handleClick.bind(this);
	}

	handleClick(){
		console.log('hoi');
	}
	
	componentWillMount() {

		const doc = new DOMParser().parseFromString(this.props.html, "text/html");
		
			
		
		//const htmlSections = doc.childNodes[0].childNodes;
		const mainSection = doc.getElementsByTagName(this.props.tag);
		let contentss = [];
		// eslint-disable-next-line
		Object.keys(mainSection).map((skey, i) => {
			let el = mainSection[skey];
			let elID = (el.getAttribute('id') !== null) ? el.getAttribute('id') : 'nothing';
			if (el.localName===this.props.tag && elID===this.props.tagid) {
				if(typeof this.props.removetag !== 'undefined' && typeof this.props.removetagid !== 'undefined'){
					//console.log('remove: '+this.props.removetag);
					let theEl = el;
					let subSections = el.getElementsByTagName(this.props.removetag);
					let removeThis = [];
					// eslint-disable-next-line
					Object.keys(subSections).map((skey, si) => {
						let sel = subSections[skey];
						//console.log('sel the id: '+sel.getAttribute('id'));
						//console.log('skey: '+skey);
						let selID = (sel.getAttribute('id') !== null) ? sel.getAttribute('id') : 'nothing';
						//console.log('selID: '+selID);
						if ((sel.localName===this.props.removetag && selID===this.props.removetagid) || (sel.localName===this.props.removetag && this.props.removetagid==='all-of-them')) {
							//console.log('del: ' + sel.localName + " " + selID);
							//console.log(theEl.getElementsByTagName(this.props.removetag)[skey]);
							//console.log(typeof theEl);
							//console.log(sel.constructor.name);
							removeThis.push(sel);
						}
					});
					if (removeThis.length!==0) {
						for (var j = 0; j < removeThis.length; j++) {
							//console.log('deleting something');
							//console.log(removeThis[i]);
							theEl.removeChild(removeThis[j]);
							// add an extra empty node to replace the one we just removed
							// this so the loop doesn't have an element short because HTMLcollection is a live collection
							var xtraNode = document.createElement(this.props.removetag);
							el.appendChild(xtraNode);
							
						}
					}
					contentss.push(theEl.innerHTML); 
				}
			}
		});
		
		this.setState({contents:contentss});
	}
	
	componentDidMount() {
		
		// add accordion functionality for loket
		// the if is so we don't break other elements using GetDOM
		if(this.props.tag==='main' && this.props.tagid==='main') {
			const faq = document.getElementById('productTabs');
			const allDivs = faq.children;
			const allDivsLength = allDivs.length;
			// eslint-disable-next-line
			Object.keys(allDivs).map((dkey, i) => {
				if(dkey<allDivsLength-1){
					let div = allDivs[i];
					let nextDiv = allDivs[i+1]
					//console.log(div);
					if(div.className==='productFaqQuestion' && nextDiv.className==='productFaqAnswers') {
						div.addEventListener('click', function(e) {
							if(typeof e.target.parentNode.getAttribute('id')!=='undefined' && e.target.parentNode.getAttribute('id') === div.getAttribute('id')) {
								if(!document.getElementById(nextDiv.getAttribute('id')).classList.contains('active')) {
									document.getElementById(nextDiv.getAttribute('id')).className = addRemoveClass(nextDiv.getAttribute('id'), 'active', 'add');
									document.getElementById(div.getAttribute('id')).className = addRemoveClass(div.getAttribute('id'), 'active', 'add');
								} 
								else {
									document.getElementById(nextDiv.getAttribute('id')).className = addRemoveClass(nextDiv.getAttribute('id'), 'active', 'remove');
									document.getElementById(div.getAttribute('id')).className = addRemoveClass(div.getAttribute('id'), 'active', 'remove');
								}
							}
						});
						
					}
				}
			});
			var addAppoint = document.createElement("DIV");
			addAppoint.setAttribute("id", "submitForm");
			addAppoint.setAttribute("onclick", "window.alert('This would show up and link to the appointment app (pre-filled) if it\\'s possible to make an appointment for this');return false;");
			var addAppointText = document.createTextNode("Make an appointment");
			addAppoint.appendChild(addAppointText);
			faq.appendChild(addAppoint);
			/*
			var replaceIt = faq; //doc.getElementById('productTabs');
			var replaceItParent = replaceIt.parentNode;
			replaceItParent.replaceChild(faq, doc.getElementById('productTabs'));
			*/
		}
	}
	
	render() {
		return <div dangerouslySetInnerHTML={{__html: this.state.contents}} />
	}
}

function addRemoveClass(where, which, what) {
	const loc = document.getElementById(where);
	let classes = loc.getAttribute('class');
	//console.log(classes);
	
	if(what==='add'){
		let classArray = classes.split(" ");
		classArray.push(which);
		return classArray.join(" ");
	}
	else if(what==='remove'){
		let classArray = classes.split(" ");
		const index = classArray.indexOf(which);
		if (index !== -1) {
			classArray.splice(index, 1);
		}
		return classArray.join(" ");
	}
	else return classes;
}

export function Content(props) {
  return (
    <div>
      <div dangerouslySetInnerHTML={{__html: props.data}}/>
    </div>
  );
}

export default App;
